<template>
  <div>
    <Header />
    <div class="bg-pattern-opaque bg-center">
      <main class="p-4 py-10 min-h-screen max-w-6xl mx-auto">
        <!-- <img src="@/assets/NG-logo-trans.png" class="w-64 mx-auto h-auto" /> -->
        <h1 class="text-black mt-4 font-bold text-base text-center" style="font-size: xx-large;font-family: Poppins;"> Love Numbers Game or get your money back. </h1>
        <h2 class="text-gray-600 text-base text-center mt-4 italic">Start making better bets in seconds.</h2>
        <div class="lg:flex lg:items-center lg:space-x-10">
          <div
            class="max-w-[540px] flex-1 bg-white border mx-auto border-black text-center mt-10 relative"
          >
            <h2 class="bg-brandGrey/20 py-4 mb-4">Analyst</h2>
            <span
              class="bg-green-400 text-xs rounded p-2 absolute -top-2 -right-2"
              >Most Popular!</span
            >
            <p class="text-center pr-[5%] pl-[5%]">
              <strong
                >Enjoy the full Numbers Game experience.</strong
              >
            </p>
            <ul class="w-full text-left pl-4 text-sm uppercase font-medium pt-5">
              <li class="flex items-center justify-center space-x-3 py-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  /></svg><span class="flex-1">SUBSCRIBER ONLY DISCORD WITH DAILY EXCLUSIVE TIPS</span>
              </li>
              <li class="flex items-center justify-center space-x-3 py-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  /></svg><span class="flex-1">AI POWERED FOOTBALL PREDICTIONS</span>
              </li>
              <li class="flex items-center justify-center space-x-3 py-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  /></svg><span class="flex-1">GLANCEABLE MATCHVIEW FOOTBALL STATS</span>
              </li>
              <li class="flex items-center justify-center space-x-3 py-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  /></svg><span class="flex-1">50+ COMPETITIONS</span>
              </li>
            </ul>
            <div class="p-4 border-t border-brandGrey/50 mx-4 pt-4 mt-4">
              <div
                class="flex space-x-2 my-4 text-center border border-black p-1 bg-brandGrey/50"
              >
                <router-link to="/signup/monthly" class="flex-1">
                  <div
                    class="flex-1 p-2 flex items-center justify-center relative text-sm"
                    :class="{
                      'bg-white text-black shadow-md rounded-sm':
                        type == 'monthly',
                      'text-brandGrey': type != 'monthly',
                    }"
                  >
                    <div class="flex-1">
                      <!-- <h3
                      class="font-bold text-xs"
                      :class="{
                        'text-white': type != 'monthly',
                      }"
                    >
                      All Leagues (monthly)
                    </h3> -->
                      <span>{{ getPrice("monthly") }} / month</span>
                    </div>
                  </div>
                </router-link>
                <router-link to="/signup/annual" class="flex-1">
                  <div
                    class="flex-1 relative p-2 flex items-center justify-center text-sm"
                    :class="{
                      'bg-white text-black shadow-md rounded-sm':
                        type == 'annual',
                      'text-brandGrey': type != 'annual',
                    }"
                  >
                    <div class="flex-1">
                      <span>{{ getPrice("annual") }} / annually</span>
                    </div>
                  </div>
                </router-link>
              </div>

              <template v-if="subscriptionType == 'inactive'">
                <template v-if="!updating">
                  <button
                    type="submit"
                    v-on:click="updateUser"
                    class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative"
                  >
                    Upgrade Account
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                    <div class="text-xs text-brandGrey py-3 hidden" id="hidden-upgrade-not-working-button"> 
                    Not working? Click <a id="hidden-upgrade-not-working-text" href="" class="text-blue-700 underline">here</a>.
                  </div>
                  </button>
                </template>
                <template v-else>
                  <button
                    v-if="!testingSignUp"
                    class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative opacity-50 cursor-not-allowed pointer-events-none"
                  >
                    Updating...
                    <div
                      class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                    ></div>
                  </button>
                  <div class="text-xs text-brandGrey py-3 hidden" id="hidden-not-working-button1"> 
                    Not working? Click <a id="hidden-not-working-text1" href="" class="text-blue-700 underline">here</a>.
                  </div>
                </template>
              </template>
              <template v-else>
                <button
                  v-on:click="toggleForm"
                  class="relative inline-flex bg-white underline items-center justify-center border border-brandGrey text-xs p-3 px-6 font-bold uppercase lg:text-lg text-blue-700"
                >
                  Get Started
                  <div
                    class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                  ></div>
                </button>
              </template>
            </div>
          </div>

          <div
            class="max-w-[540px]  flex-1 bg-white border  mx-auto border-black text-center mt-10 relative"
            v-if="!subscriptionType"
          >
            <h2 class="bg-brandGrey/20 py-4 mb-4">Punter</h2>
            <p class="text-center pr-[5%] pl-[5%]">
              <strong
                >Get a sample of the Numbers Game experience.</strong
              >
            </p>
            <ul class="w-full text-left pl-4 text-sm uppercase font-medium pt-5">
              <li class="flex items-center justify-center space-x-3 py-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  /></svg><span class="flex-1">AI POWERED PREMIER LEAGUE PREDICTIONS & STATS</span>
              </li>
              <li class="flex items-center justify-center space-x-3 py-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  /></svg><span class="flex-1">1 COMPETITION</span>
              </li>
            </ul>
            <div class=" p-4 border-t border-brandGrey/50 mx-4 pt-4 mt-4">
              <p class="uppercase text-center text-black mb-3">Free</p>
              <button
                v-on:click="toggleFreeForm"
                class="relative inline-flex bg-white underline items-center justify-center border border-brandGrey text-xs p-3 px-6 font-bold uppercase lg:text-lg text-blue-700"
              >
                Get Started
                <div
                  class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                ></div>
              </button>
            </div>
          </div>
        </div>

        <div
          class="fixed w-screen h-screen bg-black bg-opacity-70 inset-0 z-20 flex items-center justify-center"
          v-if="showForm"
          v-on:click.self="toggleForm"
        >
          <form
            class="space-y-4 text-left bg-white w-full max-w-lg p-6"
            data-rewardful
          >
            <img src="@/assets/NG-logo.jpg" class="w-64 mx-auto h-auto" />

            <p class="text-center uppercase text-black text-sm">
              Subscription - {{ niceTextType }}
            </p>

            <label class="block">
              <span class="block mb-2">email</span>
              <input
                class="border border-black p-4 w-full"
                type="email"
                v-model="email"
                :disabled="testingSignUp"
              />
            </label>

            <label class="block">
              <span class="block mb-2">password</span>
              <input
                class="border border-black p-4 w-full"
                type="password"
                v-model="password"
                :disabled="testingSignUp"
              />
            </label>

            <p class="text-sm my-4 text-red-600 text-left" v-if="error">
              {{ error }}
            </p>

            <p class="text-xs text-left text-gray-600">
              <label
                for="terms"
                class="items-center flex justify-center space-x-2"
              >
                <input
                  type="checkbox"
                  id="terms"
                  v-model="termsAccepted"
                  :disabled="testingSignUp"
                />
                <span class="flex-1"
                  >I have read and accepted the
                  <a
                    href="/disclaimer#termsandconditions"
                    class="underline"
                    target="_blank"
                    >terms and conditions</a
                  >.
                </span>
              </label>
            </p>

            <div class="text-center">
              <template v-if="!submitting">
                <button
                  type="submit"
                  v-on:click="testSignUp"
                  class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative"
                >
                  {{ testingSignUp ? "Signing up..." : "Sign up" }}
                  <div
                    class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                  ></div>
                </button>
                <div class="text-xs text-brandGrey py-3 hidden" id="hidden-signup-not-working-button"> 
                    Not working? Click <a id="hidden-signup-not-working-text" href="" class="text-blue-700 underline">here</a>.
                  </div>
              </template>
              <template v-else>
                <button
                  class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative opacity-50 cursor-not-allowed pointer-events-none"
                >
                  Creating account...
                  <div
                    class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                  ></div>
                </button>
              </template>
              <template v-if="!updating && !testingSignUp">
                <button
                  type="submit"
                  v-on:click="updateUser"
                  v-if="subscriptionType == 'inactive'"
                  class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative"
                >
                  Upgrade Account
                  <div
                    class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                  ></div>
                </button>
              </template>
              <template v-else>
                <button
                  v-if="!testingSignUp"
                  class="bg-gray-300 border mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative opacity-50 cursor-not-allowed pointer-events-none"
                >
                  Updating...
                  <div
                    class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                  ></div>
                  <div class="text-xs text-brandGrey py-3 hidden" id="hidden-not-working-button2"> 
                    Not working? Click <a id="hidden-not-working-text2" href="" class="text-blue-700 underline">here</a>.
                  </div>
                </button>
              </template>
            </div>

          </form>
        </div>
      </main>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import API from "./api";
// import LeagueListFree from "./components/LeagueListFree.vue";
// import LeagueList from "./components/LeagueList.vue";

import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies, { expires: "7d" });

export default {
  name: "Signup",
  components: {
    Header,
    Footer,
    // LeagueList,
    // LeagueListFree
  },
  props: ["storeUTMValues"],
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      submitting: false,
      updating: false,
      termsAccepted: false,
      error: false,
      showForm: false,
      testingSignUp: false,
    };
  },
  mounted() {
    if (
      this.type == "free" ||
      this.type == "annual" ||
      this.type == "monthly"
    ) {
      console.log("Correct payment type detected");
    } else {
      console.log("Incorrect payment type, reverting to monthly");
      this.$router.push("/signup/monthly");
    }

    if (this.subscriptionType == "active") {
      this.$router.push("/predictions");
    }
    if (this.subscriptionType == "inactive" && this.type == "free") {
      this.$router.push("/signup");
    }
    if (this.$route.query.showPopup) {
      this.toggleForm();
    }

    // tiktok tracking
    // eslint-disable-next-line no-undef
    ttq.track("ViewContent", {
      value: "1", // number. Value of the order or items sold. Example: 100.
      currency: "GBP", // string. The 4217 currency code. Example: "USD".
      contents: [
        {
          content_id: "1", // string. ID of the product. Example: "1077218".
          content_type: "product", // string. Either product or product_group.
          content_name: "signup", // string. The name of the page or product. Example: "shirt".
          price: "subscription", // number. The price of a single item. Example: 25.
        },
      ],
    });
  },

  computed: {
    stripeProductCode() {
      return this.$store.getters.stripeProductCode;
    },
    type() {
      return this.$route.params.type;
    },
    subscriptionType() {
      return this.$store.getters.subscriptionType;
    },
    niceTextType() {
      let niceType = "";
      if (this.type == "monthly") {
        return `${this.getPrice(this.type)} / month`;
      }
      if (this.type == "annual") {
        return `${this.getPrice(this.type)} / year`;
      }
      if (this.type == "free") {
        return "Free";
      }
      return niceType;
    },
    nextMonth() {
      return "06/08/2022";
    },
    isAmericas() {
      return false;
      // return this.$store.getters.isAmericas;
    },
  },
  methods: {
    getPrice(type) {
      if (type == "monthly") {
        return "£12.99";
        // return this.isAmericas ? "$25" : "£12.99";
      } else {
        return "£68.99";
        // return this.isAmericas ? "$125" : "£68.99";
      }
    },
    toggleForm() {
      if (this.testingSignUp) {
        return;
      }
      this.showForm = !this.showForm;
      if (!this.showForm) {
        if (this.type == "free") {
          this.$router.push("/signup/monthly");
        }
      }
    },
    toggleFreeForm() {
      if (this.$route.path != "/signup/free") {
        this.$router.push("/signup/free");
      }
      this.toggleForm();
    },
    testSignUp(event) {
      event.preventDefault();
      var passwordValidator = require("password-validator");
      var schema = new passwordValidator();

      schema.has().uppercase().has().digits(1);

      if (
        this.email &&
        this.password &&
        schema.validate(this.password) &&
        this.termsAccepted
      ) {
        this.testingSignUp = true;
        this.submitSignUp();
      } else {
        if (!this.email) {
          this.error = "Please enter a valid email and password";
        } else if (!schema.validate(this.password)) {
          this.error =
            "Passwords must contain upper and lower case, at least one number and 8+ characters.";
        } else if (!this.termsAccepted) {
          this.error = "Please accept the terms and conditions.";
        }
      }
    },
    async submitSignUp() {
      this.error = false;
      let data = {
        email: this.email,
        password: this.password,
        ...this.storeUTMValues,
      };
      if (this.type == "free" || this.stripeProductCode == false) {
        API.post("/user/signup", false, data)
          .then((response) => {
            this.$store.commit("storeUserID", response.data.user_id);

            this.$store.commit(
              "storeSubscriptionType",
              response.data.subscription
            );
            this.$store.commit(
              "storeCustomerID",
              response.data.stripe_customer_id
            );
            this.submitLogIn();
          })
          .catch((error) => {
            if (error.response.data) {
              this.testingSignUp = false;
              this.submitting = false;
              this.error = error.response.data.body
                ? error.response.data.body
                : error.response.data;
            }
          });
      } else {
        API.post("/user/signup", false, data)
          .then(() => {
            this.getStripeURL(this.stripeProductCode);
          })
          .catch((error) => {
            if (error.response.data) {
              this.testingSignUp = false;
              this.submitting = false;
              this.error = error.response.data.body
                ? error.response.data.body
                : error.response.data;
            }
          });
      }
    },
    async submitLogIn() {
      let data = {
        email: this.email,
        password: this.password,
      };

      API.post("/user/login", false, data)
        .then((response) => {
          this.$store.commit("storeAccessTokens", response.data);
          window.location = window.location.origin + "/predictions";
          // this.submitting = false;
          console.log("Logged in");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data) {
            this.submitting = false;
            this.error = error.response.data
              ? error.response.data
              : error.response.data.body;
          }
        });
    },
    async getStripeURL() {
      // if (event) {
      //   event.preventDefault();
      // }
      let data = {
        email: this.email,
        password: this.password,
      };

      API.post("/user/login", false, data)
        .then((response) => {
          this.$store.commit("storeAccessTokens", response.data);
          let auth = {
            idToken: this.$store.getters.accessTokens["id_token"],
          };
          API.get("/user", auth)
            .then((response) => {
              let data = {
                priceId: this.stripeProductCode || "price_1O4LAaDBCNN8YneAL2a3fMKD",
                customerId: response.data.stripe_customer_id,
                customerUrl: this.$route.path,
                email: this.email,
                ttclid: this.$cookies.get("ttclid"),
                ttp: this.$cookies.get("_ttp"),
                user: response.data,
                useragent: navigator.userAgent,
                clientReferenceId:
                  (window.FPROM && window.FPROM.data.tid) ||
                  "fp_" + new Date().getTime(), //used for firstpromoter
              };
              API.post("/user/stripe_session", false, data)
                .then((response) => {
                  // eslint-disable-next-line no-undef
                  gtag("event", "begin_checkout", {
                    event_callback: function () {
                      window.location = response.data.url;
                      setTimeout(function (){
                        if (document.getElementById("hidden-not-working-button1") != null) {
                          document.getElementById("hidden-not-working-button1").classList.remove("hidden");
                          document.getElementById("hidden-not-working-text1").href = response.data.url;  
                        }

                        if (document.getElementById("hidden-not-working-button2") != null) {
                          document.getElementById("hidden-not-working-button2").classList.remove("hidden");
                          document.getElementById("hidden-not-working-text2").href = response.data.url;  
                        }

                        if (document.getElementById("hidden-signup-not-working-button") != null) {
                          document.getElementById("hidden-signup-not-working-button").classList.remove("hidden");
                          document.getElementById("hidden-signup-not-working-text").href = response.data.url;  
                        }

                        if (document.getElementById("hidden-upgrade-not-working-button") != null) {
                          document.getElementById("hidden-upgrade-not-working-button").classList.remove("hidden");
                          document.getElementById("hidden-upgrade-not-working-text").href = response.data.url;  
                        }
                         
                      }, 1000);
                    },
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          if (error.response.data) {
            this.submitting = false;
            this.error = error.response.data
              ? error.response.data
              : error.response.data.body;
          }
        });
    },
    async updateUser() {
      this.updating = true;
      if (event) {
        event.preventDefault();
      }
      let auth = {
        idToken: this.$store.getters.accessTokens["id_token"],
      };

      API.get("/user", auth)
        .then((response) => {
          let data = {
            priceId: this.stripeProductCode,
            customerId: response.data.stripe_customer_id,
            customerUrl: this.$route.path,
            email: this.email,
            ttclid: this.$cookies.get("ttclid"),
            ttp: this.$cookies.get("_ttp"),
            user: response.data,
            useragent: navigator.userAgent,
            clientReferenceId:
              (window.FPROM && window.FPROM.data.tid) ||
              "fp_" + new Date().getTime(), //used for firstpromoter
          };
          API.post("/user/stripe_session", false, data)
            .then((response) => {
              // eslint-disable-next-line no-undef
              gtag("event", "begin_checkout", {
                event_callback: function () {
                  window.location = response.data.url;
                  setTimeout(function (){
                    if (document.getElementById("hidden-not-working-button1") != null) {
                          document.getElementById("hidden-not-working-button1").classList.remove("hidden");
                          document.getElementById("hidden-not-working-text1").href = response.data.url;  
                    }

                    if (document.getElementById("hidden-not-working-button2") != null) {
                      document.getElementById("hidden-not-working-button2").classList.remove("hidden");
                      document.getElementById("hidden-not-working-text2").href = response.data.url;  
                    }

                    if (document.getElementById("hidden-signup-not-working-button") != null) {
                      document.getElementById("hidden-signup-not-working-button").classList.remove("hidden");
                      document.getElementById("hidden-signup-not-working-text").href = response.data.url;  
                    }

                    if (document.getElementById("hidden-upgrade-not-working-button") != null) {
                      document.getElementById("hidden-upgrade-not-working-button").classList.remove("hidden");
                      document.getElementById("hidden-upgrade-not-working-text").href = response.data.url;  
                    }
                  }, 1000);
                }
              });
            })
            .catch((error) => {
              if (error.response.data) {
                this.submitting = false;
                this.updating = false;
                this.error = error.response.data.body;
              }
            });
        })
        .catch((error) => {
          if (error.response.data) {
            this.submitting = false;
            this.updating = false;
            this.error = error.response.data.body;
          }
        });
    },
  },
};
</script>

<style></style>
